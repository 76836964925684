<template>
  <div class="zone">
    <TJDetailTitle :titleData="'基本信息'" />
    <TJDetailMenu :dataList="dataList" />

    <TJDetailTitle :titleData="'产品图片'" />
    <el-image
      style="width: 300px; height: 300px"
      :src="detailData.image"
      :fit="fit"
    > 
      <span slot = error>暂无图片</span>
    </el-image>
  </div>
</template>

<script>
import apiDetail from '@/api/searchDetail.js'
import TJDetailTitle from '@/components/TJDetailTitle'
import TJDetailMenu from '@/components/TJDetailMenu'
export default {
  name: 'ProductReagent',
  components: { TJDetailTitle, TJDetailMenu },

  data() {
    return {
      fit: '',
      url: '',
      detailId:'',
      detailData: {},
      dataList: [
        {
          name: '注册证号',
          value: '',
          width: '25%',
        },
        {
          name: '产品名称',
          value: '',
          width: '25%',
        },
        {
          name: '规格',
          value: '',
          width: '25%',
        },
        {
          name: '是否在售',
          value: '',
          width: '25%',
          rightBorder: false,
        },
        {
          name: '人份数',
          value: '',
          width: '25%',
          bottomBorder: false,
        },
        {
          name: 'DI',
          value: '',
          width: '25%',
          bottomBorder: false,
        },
      ],
    }
  },
  created() {
    this.detailId = this.$route.query.id
    this.gitproductDetail()
  },
  methods: {
    gitproductDetail() {
      apiDetail.productDetail({ inventory_id: this.detailId }).then((res) => {
        this.detailData = res.data
        this.dataList[0].value = this.detailData.inventory_id
        this.dataList[1].value = this.detailData.reagent_name
        this.dataList[2].value = this.detailData.standard
        this.dataList[3].value = this.detailData.is_sell? '是' : '否'
        this.dataList[4].value = this.detailData.standard_num
        this.dataList[5].value = this.detailData.di
      })
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
